@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
/*App styling */
:root {
  /* Set the background of the entire app */
  --ion-background-color: #ffffff;
  --ion-color-primary: #db1921;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-secondary: #3b3a36;
  --ion-color-secondary-contrast: #ffffff;

  /* Set the font family of the entire app */
  --ion-font-family: "Roboto Condensed", sans-serif;
}

ion-button {
  --background-activated: #ae161f;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.sigCanvas {
}

.fixed-label {
  min-width: 60% !important;
  max-width: 60% !important;
}

.fixed-ion-select {
  text-align: center !important;
  min-width: 40% !important;
  max-width: 40% !important;
}

.custom-item-disabled {
  opacity: 1 !important;
}

.custom-label-disabled {
  opacity: 1 !important;
}

.btn-disabled.button-solid.button-disabled {
  --background: gray !important;
}

.button-disabled {
  opacity: 1;
  --background: #cccccc;
}

.wrap-text {
  overflow: hidden;
  white-space: normal;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

/* Without setting height the slides will take up the height of the slide's content */
ion-slides {
  height: 100%;
}

